<template>
  <v-row class="d-flex justify-center">
    <v-col cols="12" md="8" class="pa-0">
      <v-card
        class="mx-xs-0 mx-sm-auto px-xs-0 px-md-4 py-4"
        max-width="100%"
        outlined
      >
        <CardToolbar
          :disableAdd="loading"
          :disableRefresh="loading"
          :icon="AGENT_ICON"
          @add-data="openDialog()"
          @refresh-data="refreshEntries()"
          button-mode
          include-add
          include-help
          include-refresh
          title="Agents"
        />
        <v-card-text class="mt-n4">
          <p :class="`${DEFAULT_TEXT}`">A listing of all agents.</p>
        </v-card-text>

        <HelpText :page="AGENTS_VUE" />

        <v-data-table
          id="agent-table"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, -1] }"
          :headers="headers"
          :height="500"
          :items-per-page="25"
          :items="agents"
          :loading="loading"
          :search="search"
          @click:row="viewAgent"
          class="mx-4"
          fixed-header
          loading-text="Loading, please wait..."
          no-data-text="Table contains no data."
          no-results-text="No results found."
          sort-by="id"
        >
          <template v-slot:top>
            <v-row dense>
              <v-col class="offset-sm-2 col-sm-8 offset-md-3 col-md-6 mb-4" cols="12">
                <v-text-field
                  v-model="search"
                  :prepend-icon="SEARCH_ICON"
                  label="Search"
                  single-line
                  clearable
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-card>

      <dialog-crud
        :dialog.sync="dialog.show"
        @save-data="createCompleteAgent(dialog.payload)"
        :config="dialog.config"
        :payload="dialog.payload"
        :saving="dialog.saving"
      />
    </v-col>
  </v-row>
</template>

<script>
import { cloneDeep, flatten } from 'lodash'
import { mapActions, mapGetters } from 'vuex'

import Agent, { setSelectValidData, TYPE, PROPERTY, } from '../models/dto/Agent'
import { ICONS, ROUTES, VUES, TEXT_COLOR, VALID_TABLES } from '../constants'
import { now, transformError } from '../services/utility.service'
import { push } from '../router'

export default {
  components: {
    CardToolbar: () => import('../components/layout/toolbars/CardToolbar.vue'),
    DialogCrud: () => import('../components/layout/dialog/DialogCrud.vue'),
    HelpText: () => import('../components/layout/HelpText.vue'),
  },
  data: () => ({
    agent: new Agent(),
    agents: [],
    headers: [
      { text: 'ID', align: 'right', value: 'agent_id', },
      { text: 'First Name', align: 'left', value: 'first_name' },
      { text: 'Surname', align: 'left', value: 'surname' },
    ],
    loading: false,
    search: null,

    // Dialog window state.
    dialog: { show: false, config: {}, payload: {}, saving: false, },
  }),
  computed: {
    ...mapGetters({
      getValidData: 'valid/getData',
      user: 'user/getUser',
    }),
    /**
     * Metadata required by API calls.
     */
    apiCallMetadata () {
      return { operator_id: this.user.id, dlc: now(), }
    },
  },
  methods: {
    ...mapActions({
      createFullAgent: 'agent/createFullAgent',
      fetchAgents: 'agent/fetchAgents',
      
      errorMessage: 'message/errorMessage',
      successMessage: 'message/successMessage',
      
      initValidStore: 'valid/initStore',
    }),
    createCompleteAgent (data) {
      const payload = {
        [TYPE.AGENT]: {
          ...this.apiCallMetadata,
          [PROPERTY.ACTIVE]: data[PROPERTY.ACTIVE],
          [PROPERTY.AGENT_ID]: data[PROPERTY.AGENT_ID],
          [PROPERTY.BOSS_BROKER_CODE]: data[PROPERTY.BOSS_BROKER_CODE],
          [PROPERTY.BROKERAGE_NUMBER]: data[PROPERTY.BROKERAGE_NUMBER],
          [PROPERTY.DESIGNATION]: data[PROPERTY.DESIGNATION],
          [PROPERTY.FIRST_NAME]: data[PROPERTY.FIRST_NAME],
          [PROPERTY.LEGACY_AGENT_ID]: data[PROPERTY.LEGACY_AGENT_ID],
          [PROPERTY.SURNAME]: data[PROPERTY.SURNAME],
          [PROPERTY.VENDOR_ID]: data[PROPERTY.VENDOR_ID],
        },
        [TYPE.AGENT_ALIAS]: {
          ...this.apiCallMetadata,
          [PROPERTY.AGENT_ID]: data[PROPERTY.AGENT_ID],
          [PROPERTY.PROFILE_ID]: 'A',
          [PROPERTY.AGENT_NAME_ALIAS]: null,
          [PROPERTY.EMAIL_SENDER_ALIAS]: null,
          [PROPERTY.EMAIL_SIGNOFF_NAME_ALIAS]: null,
          [PROPERTY.PHONE_NUMBER_ALIAS]: null,
          [PROPERTY.MOBILE_NUMBER_ALIAS]: null,
        },
        [TYPE.AGENT_PROFILE]: {
          ...this.apiCallMetadata,
          [PROPERTY.AGENT_ID]: data[PROPERTY.AGENT_ID],
          [PROPERTY.APPLICATION_URL]: data[PROPERTY.APPLICATION_URL],
          [PROPERTY.EMAIL_ADDRESS]: data[PROPERTY.EMAIL_ADDRESS],
          [PROPERTY.MOBILE_NUMBER]: data[PROPERTY.MOBILE_NUMBER],
          [PROPERTY.PHONE_EXTENSION]: data[PROPERTY.PHONE_EXTENSION],
          [PROPERTY.PHONE_NUMBER]: data[PROPERTY.PHONE_NUMBER],
          [PROPERTY.PLURAL]: data[PROPERTY.PLURAL],
          [PROPERTY.PROFILE_ID]: 'A',
          [PROPERTY.PROFILE_PHOTO_FILE]: data[PROPERTY.PROFILE_PHOTO_FILE],
          [PROPERTY.PROVINCIAL_DISCLOSURE]: data[PROPERTY.PROVINCIAL_DISCLOSURE],
          [PROPERTY.SIGNATURE_PHOTO_FILE]: data[PROPERTY.SIGNATURE_PHOTO_FILE],
          [PROPERTY.TITLE]: data[PROPERTY.TITLE],
          [PROPERTY.WEBSITE]: data[PROPERTY.WEBSITE],
        },
        [TYPE.AGENT_BUSINESS_ADDRESS]: {
          ...this.apiCallMetadata,
          [PROPERTY.ADDRESS]: data[PROPERTY.ADDRESS],
          [PROPERTY.AGENT_ID]: data[PROPERTY.AGENT_ID],
          [PROPERTY.CITY]: data[PROPERTY.CITY],
          [PROPERTY.POSTAL_CODE]: data[PROPERTY.POSTAL_CODE],
          [PROPERTY.PROVINCE]: data[PROPERTY.PROVINCE],
          [PROPERTY.VALID_ADDRESS]: data[PROPERTY.VALID_ADDRESS],
          'country': 'CA',
        },
        [TYPE.AGENT_TEAM_ASSN]: {
          ...this.apiCallMetadata,
          [PROPERTY.AGENT_ID]: data[PROPERTY.AGENT_ID],
          [PROPERTY.TEAM_NAME]: data[PROPERTY.TEAM_NAME],
        }
      }

      this.dialog.saving = true

      this.createFullAgent( { payload })
        .then(() => {
          this.refreshEntries()
          this.dialog.show = false
          this.successMessage(`Successfully added ${data[PROPERTY.FIRST_NAME]} ${data[PROPERTY.SURNAME]} to the agent list.`)
        })
        .catch(error => {
          this.errorMessage(`Failed to add ${data[PROPERTY.FIRST_NAME]} ${data[PROPERTY.SURNAME]} to the agent list. ${transformError(error)}`)
        })
        .finally(() => {
          this.dialog.saving = false
        })
    },
    /**
     * Close and reset a dialog window.
     */
    closeDialog () {
      this.dialog = { show: false, config: {}, payload: {}, saving: false, }
    },
    initTemplateConstants() {
      this.AGENT_ICON = ICONS.AGENT
      this.AGENTS_VUE = VUES.AGENTS
      this.DEFAULT_TEXT = TEXT_COLOR.DEFAULT
      this.SEARCH_ICON = ICONS.SEARCH
    },
    /**
     * Initializes and opens the dialog window for this data-type with default values set for the fields.
     * This is used for creating an Agent from scratch through the Agents page.
     */
    openDialog () {
      this.dialog = {
        show: true,
        config: {
          title: 'Create Agent',
          fields: {
            fieldGroups: flatten([
              cloneDeep(this.agent.getFieldGroups(TYPE.AGENT)),
              cloneDeep(this.agent.getFieldGroups(TYPE.AGENT_PROFILE)),
              cloneDeep(this.agent.getFieldGroups(TYPE.AGENT_BUSINESS_ADDRESS)),
              cloneDeep(this.agent.getFieldGroups(TYPE.AGENT_TEAM_ASSN)),
            ]),
            fieldGroupTitles: [
              'Agent',
              'Profile "A"',
              'Business Address',
              'Team',
            ]
          },
        },
        payload: {
          ...cloneDeep(this.agent.getDefault(TYPE.AGENT)),
          ...cloneDeep(this.agent.getDefaultItem(TYPE.AGENT_PROFILE)),
          ...cloneDeep(this.agent.getDefault(TYPE.AGENT_BUSINESS_ADDRESS)),
          ...cloneDeep(this.agent.getDefault(TYPE.AGENT_TEAM_ASSN)),
        },
        saving: false,
      }

      delete this.dialog.payload.agent_client_assn
      delete this.dialog.payload.agent_mortgage_deal_assn
      delete this.dialog.payload.default
    },
    refreshEntries (force = true) {
      this.loading = true

      this.fetchAgents({ force })
        .then(agents => {
          this.agents = agents
        })
        .catch(error => {
          this.errorMessage(`Failed to load agent list. ${transformError(error)}`)
        })
        .finally(() => {
          this.loading = false
        })
    },
    viewAgent(event) {
      push(`${ROUTES.AGENTS}/${event.agent_id}`)
    },
  },
  async created () {
    this.initTemplateConstants()

    try {
      await this.initValidStore()
      setSelectValidData({
        provinces: this.getValidData(VALID_TABLES.PROVINCE),
        agentTeams: this.getValidData(VALID_TABLES.AGENT_TEAM),
      })
      this.refreshEntries(false)
    }
    catch(error) {
      this.errorMessage(`Failed to initialize valid store. ${transformError(error)}`)
    }
  },
}
</script>

<style scoped>
button {
  outline: none;
}
#agent-table {
  cursor: pointer;
}
</style>
